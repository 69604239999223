import React from "react";

export default class ErrorShow extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <small className="text-danger">
                błąd: {JSON.stringify(this.props.error.response.data)}
            </small>
        )
    }
}
