import React from "react";

export default class CheckBox extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <span>
                <input type="hidden" name={this.props.name} value="0"/>
                <input type="checkbox"
                       id={this.props.id}
                       name={this.props.name}
                       onChange={this.props.onChange}
                       checked={this.props.checked}/>
            </span>
        )
    }
}
