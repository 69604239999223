import React from "react";

export default class SaveButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button className="btn btn-primary">
        <i className="fa fa-check"></i> {this.props.label}
      </button>
    )
  }
}
