export default class FormValidator {
  constructor(charsCounter, wordsCounter, charsMin, charsMax, wordsMin, wordsMax) {
    this.charsCounter = charsCounter;
    this.wordsCounter = wordsCounter;
    this.charsMin = charsMin;
    this.charsMax = charsMax;
    this.wordsMin = wordsMin;
    this.wordsMax = wordsMax;

    this.makeComputations();
  }

  charsCounterColor = null;
  wordsCounterColor = null;
  charsOK = null;
  wordsOK = null;
  allOK = null;

  makeComputations() {
    // ilość liter w rozbiegówce ok?
    if (this.charsCounter >= this.charsMin && this.charsCounter <= this.charsMax) {
      this.charsCounterColor = 'text-success';
      this.charsOK = true;
    } else {
      this.charsCounterColor = 'text-danger';
      this.charsOK = false;
    }

    // ilość słów w rozbiegówce ok?
    if (this.wordsCounter >= this.wordsMin && this.wordsCounter <= this.wordsMax) {
      this.wordsCounterColor = 'text-success';
      this.wordsOK = true;
    } else {
      this.wordsCounterColor = 'text-danger';
      this.wordsOK = false;
    }

    this.allOK = this.charsOK && this.wordsOK;
  }

  get getCharsCounterColor() {
    return this.charsCounterColor
  }

  get getWordsCounterColor() {
    return this.wordsCounterColor
  }

  get isOk() {
    return this.allOK
  }
}
