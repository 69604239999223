import React from "react";

export default class Counter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <span>
                {this.props.label}
                <span className={`${this.props.color} mx-1`}>
                    {this.props.counter}
                </span>
                ({this.props.min}...{this.props.max})
            </span>
        )
    }
}
