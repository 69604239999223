import React from 'react'
import SaveButton from "./SaveButton";

export default class CommentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nick: 'Twój nick',
            showButton: false,
            desc: 'komentarz',
            desc_alert: ''
        };

        this.handleNickChange = this.handleNickChange.bind(this);
        this.handleDescChange = this.handleDescChange.bind(this);
    }

    handleNickChange(event) {
        this.setState({nick: event.target.value})
    }

    handleDescChange(event) {
        const description = event.target.value.toString().replace(/\s+/g, ' ').trim();

        if (description.length < this.props.minDescLength) {
            this.setState({
                desc: description,
                desc_alert: `do minimum brakuje ${this.props.minDescLength - description.length} znaków`,
                showButton: false
            })
        } else {
            this.setState({
                desc: description,
                desc_alert: '',
                showButton: true
            })
        }
    }

    render() {
        return (
            <div>
                <input type="hidden"
                       name="comment[story_id]"
                       value={this.props.comment.story_id}/>

                <div className="row">
                    <div className="col-sm text-danger">
                        &nbsp;{this.state.desc_alert}
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-9">
                        <textarea name="comment[desc]"
                                  className="form-control"
                                  placeholder={this.state.desc}
                                  onChange={this.handleDescChange}
                                  defaultValue={this.props.comment.desc}/>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-5">
                        <input type="text"
                               name="comment[nick]"
                               className="form-control"
                               placeholder={this.state.nick}
                               onChange={this.handleNickChange}
                               defaultValue={this.props.comment.nick}/>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm">
                        {this.state.showButton ? <SaveButton label="Zapisz komentarz"/> : null}
                    </div>
                </div>
            </div>
        )
    };
}
