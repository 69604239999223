import React from "react";

export default class SaveInformer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const label = this.props.saveNeed ? 'zmiany...' : 'zmiany zapisane';

        return (
            <small className={this.props.saveNeed ? 'text-muted' : 'text-success'}>
                {this.props.show ? label : null}
            </small>
        )
    }
}
