import React from 'react'
import axios from 'axios';
import SaveButton from "../SaveButton";
import Counter from "./Counter";
import Publisher from "./Publisher";
import RowWithAuthor from "./RowWithAuthor";
import ErrorShow from "./ErrorShow";
import SaveInformer from "./SaveInformer";
import Autocomplete from "./Autocomplete";
import FormValidator from "./FormValidator";

export default class StoryForm extends React.Component {
  constructor(props) {
    super(props);

    let prefaceWordsCounter, prefaceCharsCounter;
    if (this.props.story.preface == null) {
      prefaceCharsCounter = 0;
      prefaceWordsCounter = 0;
    } else {
      prefaceCharsCounter = this.props.story.preface.replace(/ /g, '').length;
      prefaceWordsCounter = this.props.story.preface.split(' ').length;
    }

    this.state = {
      error: null,
      title: this.props.story.title,
      preface: this.props.story.preface,
      tale: this.props.story.tale,
      all_tags: this.props.story.all_tags,
      prefaceCharsCounter: prefaceCharsCounter,
      prefaceWordsCounter: prefaceWordsCounter,
      lastSavedTime: new Date(),
      saveNeed: false,
    };

    this.handleTitle = this.handleTitle.bind(this);
    this.handlePreface = this.handlePreface.bind(this);
    this.handleTale = this.handleTale.bind(this);
  }

  saveInterval = 10000; // in miliseconds

  handleTitle(event) {
    this.manageSaveNeed();
    this.setState({title: event.target.value});
  }

  handlePreface(event) {
    const chars = event.target.value.toString();
    const charsCounter = chars.replace(/ /g, '').length;
    const wordsCounter = chars.split(' ').length;

    this.manageSaveNeed();

    this.setState({
      preface: chars,
      prefaceCharsCounter: charsCounter,
      prefaceWordsCounter: wordsCounter
    });
  }

  handleTale(event) {
    this.manageSaveNeed();
    this.setState({tale: event.target.value});
  }

  saveStory() {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    axios.put(`/stories/${this.props.story.hashid}/set_story_ajax`, {
      story: {
        title: this.state.title,
        preface: this.state.preface,
        tale: this.state.tale
      }
    }, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      }
    }).then(response => {
      /// const data = response.data; bo nie mam co zrobić z tą odpowiedzią
      this.setState({
        lastSavedTime: new Date(),
        error: null,
      });
    }).catch(error => {
      this.setState({
        error
      });
    })
  }

  manageSaveNeed() {
    if (!this.state.saveNeed && this.props.story.id) {
      this.setState({saveNeed: new Date()});
    }
  }

  checkSaveNecessity = () => {
    const dateNowMinusInt = new Date() - this.saveInterval;

    if (this.state.saveNeed && this.state.saveNeed > dateNowMinusInt) {
      this.saveStory();
      this.setState({saveNeed: false});
    }
  };

  formValidator() {
    const fv = new FormValidator(
      this.state.prefaceCharsCounter,
      this.state.prefaceWordsCounter,
      this.props.prefaceCharsMin,
      this.props.prefaceCharsMax,
      this.props.prefaceWordsMin,
      this.props.prefaceWordsMax
    );

    return fv;
  }

  componentDidMount() {
    const intervalID = setInterval(this.checkSaveNecessity, this.saveInterval);
  }

  render() {
    const prefaceCharsCounter = this.state.prefaceCharsCounter;
    const prefaceWordsCounter = this.state.prefaceWordsCounter;

    const formValidator = this.formValidator();

    return (
      <div>
        <div className="row mt-3">
          <div className="col-sm-3">
            <input type="hidden" value={this.props.story.user_id} name="story[user_id]"/>

            <h5>
              tytuł
            </h5>
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
            <input type="text"
                   name="story[title]"
                   onChange={this.handleTitle}
                   defaultValue={this.props.story.title}
                   className="form-control"
                   required/>
          </div>

          <div className="col-sm">
            {!!this.state.error ?
              <ErrorShow error={this.state.error}/> :
              <SaveInformer saveNeed={this.state.saveNeed} show={this.props.story.id ? true : false}/>}
          </div>

          <div className="col-sm-3">
            {formValidator.isOk ? <Publisher ready={this.props.story.ready}/> : null}
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-sm-2">
            <h5>
              rozbiegówka
            </h5>
          </div>

          <div className="col-sm-3">
            <Counter counter={prefaceCharsCounter}
                     min={this.props.prefaceCharsMin}
                     max={this.props.prefaceCharsMax}
                     label='znaków'
                     color={formValidator.getCharsCounterColor}/>
          </div>

          <div className="col-sm-3">
            <Counter counter={prefaceWordsCounter}
                     min={this.props.prefaceWordsMin}
                     max={this.props.prefaceWordsMax}
                     label='wyrazów'
                     color={formValidator.getWordsCounterColor}/>
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
                        <textarea name="story[preface]"
                                  placeholder={`tu wpisz zachęcający początek...\nznaków ma być od ${this.props.prefaceCharsMin} do ${this.props.prefaceCharsMax}`}
                                  rows="8"
                                  onChange={this.handlePreface}
                                  defaultValue={this.props.story.preface}
                                  className="form-control"/>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-sm">
            <h5>
              dalsza treść
            </h5>
            <textarea name="story[tale]"
                      placeholder="tu wpisz właściwą treść..."
                      rows="20"
                      defaultValue={this.props.story.tale}
                      onChange={this.handleTale}
                      className="form-control"/>
          </div>
        </div>

        <RowWithAuthor author={this.props.story.author}
                       authorData={this.props.story.authorData}/>

        <div className="row mt-3">
          <div className="col-sm-2 text-right">
            <label htmlFor="story_all_tags">
              tagi (po przecinkach)
            </label>
          </div>

          <div className="col-sm">
            <Autocomplete value={this.props.story.all_tags}/>
          </div>
        </div>

        <div className="row mt-3">
          <div className="offset-sm-2 col-sm">
            <SaveButton label="Zapisz i wyjdź"/>

            <a href="/stories" className="ml-3 btn btn-outline-secondary">Anuluj</a>
          </div>
        </div>
      </div>
    )
  }
}
