import React from "react";
import CheckBox from "../CheckBox";

export default class Publisher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: this.props.ready
        };

        this.handlePublication = this.handlePublication.bind(this);
    }

    handlePublication(event) {
        this.setState({ready: !this.state.ready});
    }

    render() {
        return (
            <label className="align-middle">
                <span className="mr-2">publikować?</span>
                <CheckBox name="story[ready]"
                          onChange={this.handlePublication}
                          checked={this.state.ready}/>

                {this.state.ready ? <GreenIcon/> : <RedIcon/>}
            </label>
        )
    }
}

function RedIcon(props) {
    return (
        <span className="ml-2 text-danger h3"
              data-toggle="tooltip"
              data-placement="top"
              title="Nieopublikowany!"><i className="fa fa-ban"></i></span>
    )
}

function GreenIcon(props) {
    return (
        <span className="ml-2 text-success h3"
              data-toggle="tooltip"
              data-placement="top"
              title="Opublikowany"><i className="fa fa-check"></i></span>
    )
}
