import React from "react";
import Buttons from "./Buttons"

export default class SortMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showElements: false
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    this.setState({showElements: !this.state.showElements})
  }

  render() {
    return (
      <>
        <span onClick={this.handleClick} className="btn btn-light btn-lg btn-block">
          Sortowanie
        </span>
        {this.state.showElements ? <Buttons sortObj={this.props.sortObj}/> : null}
      </>
    )
  }
}
