import React from "react";
import SortButton from "./SortButton";

export default class Buttons extends React.Component {
  constructor(props) {
    super(props);

    // rozpoznawanie w adresie jaki jest typ sortowania aby odpowiednio "wyciszyć" przyciski
    const reg = /\?order=(.+)/;
    const regAry = reg.exec(window.location.href);
    (regAry && regAry[1]) ? this.state = {order: regAry[1]} : this.state = {order: null}
  }

  btResolv(kind, direction) {
    const urlPrefix = "/tales?order=";
    const url = `${urlPrefix}${this.props.sortObj[kind][direction]}`;
    const prefix = `fas fa-chevron-${direction} `;

    if (this.state.order && this.props.sortObj[kind][direction] == this.state.order) {
      return {url: url, iconCss: prefix + "text-secondary", btCss: ""}
    } else {
      return {url: url, iconCss: prefix + "text-primary", btCss: "btn btn-outline-secondary btn-block"}
    }
  }

  render() {
    return (
      <div className="border border-light">
        <div className="row my-3">
          <div className="col">
            <SortButton href={this.btResolv('thumbs', 'up').url}
                        direction="up"
                        btClassName={this.btResolv('thumbs', 'up').btCss}
                        text="super"
                        iconClassName={this.btResolv('thumbs', 'up').iconCss}/>
          </div>

          <div className="col">
            ocena <i className="far fa-thumbs-up"></i>
          </div>

          <div className="col">
            <SortButton href={this.btResolv('thumbs', 'down').url}
                        direction="down"
                        btClassName={this.btResolv('thumbs', 'down').btCss}
                        text="hmm"
                        iconClassName={this.btResolv('thumbs', 'down').iconCss}/>
          </div>
        </div>


        <div className="row my-3">
          <div className="col">
            <SortButton href={this.btResolv('date', 'up').url}
                        direction="up"
                        btClassName={this.btResolv('date', 'up').btCss}
                        text="nowe"
                        iconClassName={this.btResolv('date', 'up').iconCss}/>
          </div>

          <div className="col">
            data
          </div>

          <div className="col">
            <SortButton href={this.btResolv('date', 'down').url}
                        direction="down"
                        btClassName={this.btResolv('date', 'down').btCss}
                        text="stare"
                        iconClassName={this.btResolv('date', 'down').iconCss}/>
          </div>
        </div>


        <div className="row my-3">
          <div className="col">
            <SortButton href={this.btResolv('views', 'up').url}
                        direction="up"
                        btClassName={this.btResolv('views', 'up').btCss}
                        text="duża"
                        iconClassName={this.btResolv('views', 'up').iconCss}/>
          </div>

          <div className="col">
            oglądalność <i className="fa fa-eye"></i>
          </div>

          <div className="col">
            <SortButton href={this.btResolv('views', 'down').url}
                        direction="down"
                        btClassName={this.btResolv('views', 'down').btCss}
                        text="mała"
                        iconClassName={this.btResolv('views', 'down').iconCss}/>
          </div>
        </div>
      </div>
    )
  }
}

