import React from "react";
import axios from "axios";

export default class Autocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputVal: this.props.value,
            tags: [],
            isLoaded: true,
            showLoading: false,
            showSelect: false,
            tagFragment: null,
        };

        this.handleInput = this.handleInput.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(event) {
        // zamiast prosto dostawiać do imputa wartość z selecta, to podmieniam fragment wpisywany wartością wybraną z selecta
        const inputVal = this.state.inputVal.replace(this.state.tagFragment, '');
        const afterAddSelect = inputVal + event.target.value;

        this.setState({
            inputVal: afterAddSelect,
            showSelect: false
        })
    }

    handleInput(event) {
        let tagFragment = event.target.value;
        this.setState({
            isLoaded: false,
            showLoading: true,
            inputVal: tagFragment
        });

        // ostatni tag po przecinku
        tagFragment = tagFragment.split(',').map(txt => txt.trim()).slice(-1)[0];

        if (tagFragment.trim().length > 2) {
            // wrzucam do state aby później ten fragment wycinać podczas wstawiania z selecta
            this.setState({tagFragment: tagFragment});
            this.getDatabaseData(tagFragment);
        } else {
            this.setState({
                showLoading: false,
                showSelect: false
            });
        }
    }

    getDatabaseData(tagFragment) {
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

        axios.get(`/tags/all_tags`, {
            params: {
                fragment: tagFragment
            }
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrf
            }
        }).then(response => {
            const data = response.data;

            this.setState({
                isLoaded: true,
                showLoading: false,
                showSelect: true,
                tags: data
            });
        }).catch(error => {
            this.setState({
                isLoaded: true,
                showLoading: false,
                error
            });
        })
    }

    render() {
        const shouldShow = this.state.tags.length > 0 && this.state.showSelect && this.state.isLoaded;

        return (
            <div>
                <input type="text"
                       value={this.state.inputVal}
                       onChange={this.handleInput}
                       id="story_all_tags"
                       name="story[all_tags]"
                       placeholder="tag_przykładowy, tag_drugi"
                       className="form-control"/>
                {shouldShow ? <Select onChange={this.handleSelect} tags={this.state.tags}/> : null}
                {this.state.showLoading ? 'Ładowanie...' : null}
            </div>
        )
    }
}

function Select(props) {
    const tags = props.tags.map(tag => <option key={tag.id}>{tag.name}</option>);

    return (
        <select onChange={props.onChange} className="form-control" size="3">
            {tags}
        </select>
    )
}
