import React from "react";

export default class SortButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let iconInside;
    if (this.props.direction === "up") {
      iconInside = <DirectionIconUp iconClassName={this.props.iconClassName} text={this.props.text}/>
    } else {
      iconInside = <DirectionIconDown iconClassName={this.props.iconClassName} text={this.props.text}/>
    }

    return (
      this.props.btClassName == "" ?
        <span>{iconInside}</span> :
        <a href={this.props.href} className={this.props.btClassName}>{iconInside}</a>
    );
  }
}

function DirectionIconUp(props) {
  return (
    <small>
      <i className={props.iconClassName}></i>
      <span className="ml-2">{props.text}</span>
    </small>
  )
}

function DirectionIconDown(props) {
  return (
    <small>
      <span className="mr-2">{props.text}</span>
      <i className={props.iconClassName}></i>
    </small>
  )
}
