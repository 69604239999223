import React from "react";
import CheckBox from "../CheckBox";

export default class RowWithAuthor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            author: this.props.author
        };

        this.handleAuthor = this.handleAuthor.bind(this);
    }

    handleAuthor(event) {
        this.setState({author: !this.state.author});
    }

    render() {
        return (
            <div className="row mt-3">
                <div className="col-sm-2 text-right">
                    <label htmlFor="story_author">
                        ujawnić nick autora?
                    </label>
                </div>

                <div className="col-sm">
                    <CheckBox id="story_author"
                              name="story[author]"
                              onChange={this.handleAuthor}
                              checked={this.state.author}/>

                    <label htmlFor="story_author" className="ml-2">
                        {this.state.author ?
                            <AuthorIcon author={this.props.authorData}/> :
                            <AuthorAnonymousIcon/>}
                    </label>
                </div>
            </div>
        )
    }
}

function AuthorIcon(props) {
    return (
        <span className="text-muted">
            <i className="fas fa-user"></i>
            <span className="ml-2">
                <a href={`/authors/${props.author.authorId}`}>{props.author.authorName}</a>
            </span>
        </span>
    )
}

function AuthorAnonymousIcon(props) {
    return (
        <span className="text-muted">
            <svg width="24"
                 height="24"
                 focusable="false"
                 data-prefix="fad" data-icon="user-lock" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 640 512" className="svg-inline--fa fa-user-lock fa-w-20 fa-3x">
                <g className="fa-group">
                    <path
                        fill="currentColor"
                        d="M608 288h-32v-80a80 80 0 0 0-160 0v80h-32a32 32 0 0 0-32 32v160a32 32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zM496 432a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm32-144h-64v-80a32 32 0 0 1 64 0z"
                        className="fa-secondary"></path>
                    <path fill="currentColor"
                          d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm96 64a63.08 63.08 0 0 1 8.1-30.5c-4.8-.5-9.5-1.5-14.5-1.5h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h280.9a63.54 63.54 0 0 1-8.9-32z"
                          className="fa-primary"></path></g></svg>
        </span>

    )
}
