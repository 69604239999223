import React from 'react'
import axios from 'axios';

export default class CommentReady extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            backend: null,
            checked: (this.props.ready ? true : false)
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            isLoaded: false,
            checked: !this.state.checked
        });

        this.changeDatabaseData(event.target.checked);
    }

    changeDatabaseData(bool) {
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

        axios.put(`/comments/${this.props.comment_id}/set_ready`, {
            comment: {ready: bool}
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrf
            }
        }).then(response => {
            const data = response.data;
            this.setState({
                isLoaded: true,
                backend: data
            });
        }).catch(error => {
            this.setState({
                isLoaded: true,
                error
            });
        })
    }

    render() {
        const error = this.state.error;
        const isLoaded = this.state.isLoaded;
        const backend = this.state.backend;

        if (error) {
            return <ErrorMessage message={error.message}/>;
        } else if (backend) {
            return <ErrorMessage message={backend}/>;
        } else if (!isLoaded) {
            return (
                <div>Ładuję...</div>
            )
        } else {
            return (
                <input type="checkbox"
                       id="ready"
                       name="ready"
                       onChange={this.handleChange}
                       checked={this.state.checked}/>
            )
        }


    }
}

class ErrorMessage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="text-danger">{this.props.message}</div>
        )
    }
}
